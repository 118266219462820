<script setup>
import { useHotelStore } from '~/store/hotel'
import { request } from '@/utils/http'
import { jumpToRefidUrl } from '~/utils/refid'

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
})
const hotelStore = useHotelStore()
const data = reactive({
  isClient: process.client,
})
const cityInput = ref()
const kwInput = ref()
const baseUrl = hotelStore.getBaseUrl()
const { t } = useLang()
const isLogin = useComCookie('sectoken').value

// useCookie('locale').value = 'en-us' // cl测试 en-us
// useCookie('currency').value = 'USD' // cl测试 USD

onMounted(() => {
  setTimeout(() => {
    if (process.client) {
      hotelStore.getHotCitys()
      hotelStore.getHotKeywords()
      hotelStore.getRecCitys()
      getPreloadToken()
    }
  })

  process.client && hotelStore.isZh && getFont()
})

watch(() => props.isVisible, (newVal) => {
  newVal && getPreloadToken()
})

function getFont() {
  const style = document.createElement('style')
  style.innerHTML = `@font-face {
    font-family: "PingFang_SC";
    src: url("https://m.elongstatic.com/hotel_pc_i18n/PingFangSC-Medium-1023.woff2");
  }
  .PingFang_SC {
    font-family: "PingFang_SC";
    font-variant-east-asian: traditional;
  }`
  document.head.appendChild(style)
}

// 城市搜索
async function getRegionsug() {
  const res = await http({
    url: '/tapi/v2/regionsug',
    baseURL: hotelStore.getHotelApiHost(),
    query: {
      keywords: toJian(hotelStore.city.showName || ''),
      inDate: hotelStore.indate.value,
      outDate: hotelStore.outdate.value,
      time: Date.now(),
    },
    headers: hotelStore.getHotelApiHeaders(),
  })
  const list = res?.data || []
  hotelStore.searchList = list
  hotelStore.setCitySearchListSelector(true)
}
// 关键词搜索
async function getKeywordssug() {
  const res = await http({
    url: '/tapi/v2/keywordssug',
    baseURL: hotelStore.getHotelApiHost(),
    query: {
      cityId: hotelStore.city.cityId,
      keywords: toJian(hotelStore.keywords.name || ''),
      inDate: hotelStore.indate.value,
      outDate: hotelStore.outdate.value,
      time: Date.now(),
    },
    headers: hotelStore.getHotelApiHeaders(),
  })
  hotelStore.searchKeywordsObj = {
    kwList: res?.data?.keywordsResponse || [],
    cityList: res?.data?.regionResponse || [],
  }
  hotelStore.setKeywordsSearchListSelector(true)
}

// 点击城市选择
function clickCity() {
  if (hotelStore.citySearchListSelector) {
    return
  }
  hotelStore.setCitySelector(true)
}

// 点击关键词选择
function clickKeywords() {
  if (hotelStore.keywordsSelector) {
    return
  }
  hotelStore.setKeywordsSelector(true)
  hotelStore.setCityShowName()
}

let timer = null
// 城市输入框
function inputCity() {
  clearTimeout(timer)
  if (!hotelStore.city.showName.trim()) {
    hotelStore.setCitySelector(true)
    return
  }
  timer = setTimeout(() => {
    getRegionsug()
  }, 500)
}
function blurCity() {
  // setPrevShowName()
  // hotelStore.hideAllLayer()
}
// 关键词输入框
function inputKeywords() {
  clearTimeout(timer)
  if (!hotelStore.keywords.name.trim()) {
    hotelStore.setKeywordsSelector(true)
    return
  }

  const name = hotelStore.keywords.name || ''
  hotelStore.setKeywords({ name })

  timer = setTimeout(() => {
    getKeywordssug()
    getPreloadToken()
  }, 500)
}
function blurKeywords() {

}

// 点击城市搜索下拉
function clickItemAssCity(item) {
  const isCity = item.typeId === 2
  const isHotel = item.typeId === 1036
  const city = {
    cityId: item.cityId,
    cityName: item.cityName,
    cityType: item.countryAreaType,
  }

  // 酒店 跳走
  if (isHotel) {
    const opt = {
      city: city.cityId,
      inDate: hotelStore.indate.value,
      outDate: hotelStore.outdate.value,
      keywords: item.regionName || '',
      intl: hotelStore.getIntl(city),
      refid: $utils.getRefid() || '',
    }
    $utils.delEmpty(opt)
    const url = `${baseUrl}/hotel/hotellist?${$utils.jsonToQuery(opt)}`
    $utils.go(url)
  }
  else {
    hotelStore.setCity(city)
    hotelStore.hideAllLayer()

    if (isCity) {
      const n = hotelStore.city
      $utils.sendEvent('', '', '酒店主搜', `^城市^模块:直接输入^城市ID:${n.cityId}^城市名称:${n.cityName}^`)
    }
    else {
      item.name = item.keywordsName || item.regionName || ''
      hotelStore.setKeywords(item)
      const n = item
      $utils.sendEvent('', '', '酒店主搜', `^关键词^${n.name}^${n.filterId}^${n.typeId}^`)
    }

    getPreloadToken()
  }
}

// 点击关键词搜索下拉
function clickItemAssKeywords(item) {
  const isCity = item.typeId === 2
  const isHotel = item.typeId === 1036
  const city = {
    cityId: item.cityId,
    cityName: item.cityName,
    cityType: item.countryAreaType,
  }
  // 酒店 跳走
  if (isHotel) {
    const opt = {
      city: city.cityId || hotelStore.city.cityId, // fix:同城资源搜索下，取值 cityid 为空兼容，兜底当前城市 cityid
      inDate: hotelStore.indate.value,
      outDate: hotelStore.outdate.value,
      keywords: item.regionName || item.keywordsName,
      intl: hotelStore.getIntl(city),
      refid: $utils.getRefid() || '',
    }
    $utils.delEmpty(opt)
    const url = `${baseUrl}/hotel/hotellist?${$utils.jsonToQuery(opt)}`
    $utils.go(url)
  }
  else {
    hotelStore.setCity(city)
    hotelStore.hideAllLayer()

    if (isCity) {
      const n = hotelStore.city
      $utils.sendEvent('', '', '酒店主搜', `^城市^模块:直接输入^城市ID:${n.cityId}^城市名称:${n.cityName}^`)
    }
    else {
      item.name = item.keywordsName || item.regionName || ''
      hotelStore.setKeywords(item)
      const n = item
      $utils.sendEvent('', '', '酒店主搜', `^关键词^${n.name}^${n.filterId}^${n.typeId}^`)
    }
    getPreloadToken()
  }
}

// 点击日历选择
function clickCalendar() {
  hotelStore.setCalendarSelector(true)
  hotelStore.setCityShowName()
}

// 点击人数选择
function clickGuest() {
  hotelStore.setGuestSelector(true)
  hotelStore.setCityShowName()
}

// 选择星级
function selectStar(n) {
  hotelStore.selectStar(n)
  getPreloadToken()
}

// 查询
function submit() {
  const filterList = [hotelStore.keywords.filterList || '']
  hotelStore.stars.forEach((n) => {
    n.select && filterList.push(`1008_${n.value}`)
  })
  const opt = {
    city: hotelStore.city.cityId,
    inDate: hotelStore.indate.value,
    outDate: hotelStore.outdate.value,
    adultsNumber: hotelStore.adult,
    childrenAges: hotelStore.ages.map(n => n.value).join(','),
    filterList: filterList.filter(n => n).join(','),
    keywords: encodeURIComponent(hotelStore.keywords.name || ''),
    intl: hotelStore.getIntl(),
    refid: $utils.getRefid() || '',
    preLoadToken: sessionStorage.getItem('hotel-listprelaod-token') || '',
  }
  if (hotelStore.keywords.filterList) {
    delete opt.keywords
  }
  $utils.delEmpty(opt)
  const url = jumpToRefidUrl(`${baseUrl}/hotel/hotellist?${$utils.jsonToQuery(opt)}`)
  console.log(url)
  $utils.go(url)

  const typeId = hotelStore.keywords.typeId || ''
  const filterId = hotelStore.keywords.filterId || ''
  const keywords = hotelStore.keywords.name || ''
  const star = hotelStore.stars.filter(n => n.select).map(n => n.value).join(',')
  $utils.sendEvent('', '', '酒店主搜', `^查询^当前城市:${hotelStore.city.cityName}^当前城市id:${hotelStore.city.cityId}^商圈id:${filterId}^商圈类型id:${typeId}^入店日期:${hotelStore.indate.value}^离店日期:${hotelStore.outdate.value}^关键词:${keywords}^星级:${star}^`)
  opt.preLoadToken && $utils.sendEvent('', '', '酒店主搜', `酒店预加载token:${opt.preLoadToken}`)
}

if (process.client) {
  document.body.addEventListener('click', () => {
    hotelStore.hideAllLayer()
    setPrevShowName()
  })

  window.hotelStore = hotelStore // cl测试
}

function setPrevShowName() {
  const name = hotelStore.city.showName.trim()
  if (name !== hotelStore.city.cityName) {
    hotelStore.city.showName = hotelStore.city.cityName
  }
}

function getPreloadToken() {
  const token = useComCookie('sectoken').value
  sessionStorage.setItem('hotel-listprelaod-token', '')
  if (token) {
    const filterList = [hotelStore.keywords.filterList || '']
    hotelStore.stars.forEach((n) => {
      n.select && filterList.push(`1008_${n.value}`)
    })
    const preloadParams = {
      city: hotelStore.city.cityId,
      cityType: hotelStore.city.cityType,
      inDate: hotelStore.indate.value,
      outDate: hotelStore.outdate.value,
      adultsNumber: hotelStore.adult,
      childrenAges: hotelStore.ages.map(n => n.value),
      filterList: filterList.filter(n => n).join(','),
      keywords: encodeURIComponent(hotelStore.keywords.name || ''),
    }
    console.warn('filterList', hotelStore.keywords.filterList)
    if (hotelStore.keywords.filterList) {
      delete preloadParams.keywords
    }
    listPreload(preloadParams)
  }
}

let lastestTimeStamp = 0

function listPreload(params) {
  // 每次请求清空上次存储值
  const key = 'hotel-listprelaod-token'
  const clientInfo = {
    deviceId: useComCookie('H5CookieId').value,
    lang: useComCookie('locale').value,
    country: '',
    currency: useComCookie('currency').value,
  }
  request({
    method: 'POST',
    url: '/sea_shell/sea_octopus_api/hotel/listpreload',
    data: {
      channelId: 1,
      clientInfo: JSON.stringify(clientInfo),
      ...params,
    },
    includeClientInfo: false,
    responseOnlyBody: false,
  }).then((res) => {
    console.warn(res._beginTime, '酒店预加载')
    if (res._beginTime > lastestTimeStamp) {
      lastestTimeStamp = res._beginTime
      sessionStorage.setItem(key, res.body || '')
    }
  })
}

function onDone() {
  getPreloadToken()
}
</script>

<template>
  <div class="hotel_search_body">
    <div class="search_ul">
      <!-- 城市 -->
      <div class="li_box">
        <div class="flex flex-col justify-center li li1" @click.stop="clickCity">
          <div class="txt_1">
            {{ t('home.s_hotel_city_title') }}
          </div>
          <div class="txt_2">
            <ClientOnly>
              <input ref="cityInput" v-model="hotelStore.city.showName" type="text" autocomplete="off" :placeholder="t('home.s_hotel_city_placeholder')" class="truncate city_input PingFang_SC" @input="inputCity" @blur="blurCity">
            </ClientOnly>
          </div>
        </div>
        <HotelCity v-if="hotelStore.citySelector" @on-done="onDone" />
        <HotelSearchList v-if="hotelStore.citySearchListSelector" :data="hotelStore.searchList" :extend-data="[]" :is-intl="!!hotelStore.getIntl()" @click-item="clickItemAssCity" />
      </div>

      <!-- 日期 -->
      <div class="li_box">
        <div class="flex items-center justify-between li li2" @click.stop="clickCalendar">
          <div>
            <div class="txt_1">
              {{ t('home.s_hotel_date_checkin') }}
            </div>
            <div class="txt_2">
              <ClientOnly>{{ hotelStore.showDateStr(hotelStore.indate.value) }}</ClientOnly>
            </div>
          </div>
          <div class="txt_3">
            <ClientOnly>{{ hotelStore.days }}</ClientOnly>{{ t(hotelStore.getNightUnit(hotelStore.days)) }}
          </div>
          <div>
            <div class="txt_1">
              {{ t('home.s_hotel_date_checkout') }}
            </div>
            <div class="txt_2">
              <ClientOnly>{{ hotelStore.showDateStr(hotelStore.outdate.value) }}</ClientOnly>
            </div>
          </div>
        </div>
        <HotelCalendar v-if="hotelStore.calendarSelector" @on-done="onDone" />
      </div>

      <!-- 成人、儿童 -->
      <div class="li_box">
        <div class="flex flex-col justify-center li li3" @click.stop="clickGuest">
          <div class="txt_1">
            {{ t('home.s_hotel_guests_title') }}
          </div>
          <div class="txt_2">
            <ClientOnly>
              {{ hotelStore.adult }} {{ t(hotelStore.getAdultUnit(hotelStore.adult)) }}, {{ hotelStore.child }} {{ t(hotelStore.getChildtUnit(hotelStore.child)) }}
            </ClientOnly>
          </div>
        </div>
        <HotelGuests v-if="hotelStore.guestSelector" @on-done="onDone" />
      </div>

      <!-- 关键词 -->
      <div class="li_box">
        <div class="flex flex-col justify-center li li4" @click.stop="clickKeywords">
          <div class="txt_1">
            {{ t('home.s_hotel_keywords_title') }}
          </div>
          <div class="txt_2">
            <ClientOnly>
              <input ref="kwInput" v-model="hotelStore.keywords.name" type="text" autocomplete="off" :placeholder="t('home.s_hotel_keywords_placeholder')" class="truncate city_input PingFang_SC" @input="inputKeywords" @blur="blurKeywords">
            </ClientOnly>
          </div>
        </div>
        <HotelKeywords v-if="hotelStore.keywordsSelector" @on-done="onDone" />
        <HotelSearchList v-if="hotelStore.keywordsSearchListSelector" :data="hotelStore.searchKeywordsObj.kwList" :extend-data="hotelStore.searchKeywordsObj.cityList" :is-intl="!!hotelStore.getIntl()" @click-item="clickItemAssKeywords" />
      </div>

      <div class="li_box" data-btn-search-type="hotel" @click="submit">
        <div class="li li5 flex-center">
          {{ t('home.s_hotel_search') }}
        </div>
      </div>
    </div>
    <div class="flex stars_box">
      <div class="star_title">
        {{ t('home.s_hotel_star_title') }}
      </div>
      <div v-for="n in hotelStore.stars" :key="n.key" class="star_li flex-center" :class="{ star_li_select: n.select }" @click="selectStar(n)">
        <span>{{ n.text }}</span>
        <span class="star_icon flex-center ">
          <span class="star_icon_img" />
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.hotel_search_body {
  cursor: default;
}

.search_ul {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
}
.li_box {
  position: relative;
  background: #F7F8FB;
  border-radius: 8px;
}
.li_box+.li_box {
  margin-left: 10px;
}

.li {
  width: 200px;
  height: 70px;
  padding: 0 20px;
  background: #F7F8FB;
  border-radius: 8px;
}

.txt_1 {
  font-size: 14px;
  color: #989898;
}
.txt_2 {
  font-weight: 600;
  font-size: 18px;
  white-space: nowrap;
}
.txt_3 {
  font-size: 14px;
  color: #000;
}

.city_input {
  width: 100%;
  outline: 0;
  background: none;
}
.city_input::placeholder {
  font-weight: normal;
  font-size: 13px;
}

.li2 {
  width: 350px;
}

.li3 {
  width: 204px;
  padding:  0 15px;
}

.li4 {
  width: 196px;
}

.li5 {
  width: 140px;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  background: var(--Brand-Green);
  cursor: pointer;
}

.stars_box {
  margin: 16px 0 0 32px;
}
.star_title {
  font-size: 16px;
  color: #000;
  line-height:30px;
}
.star_li {
  width: 70px;
  height: 30px;
  margin-left: 10px;
  font-weight: 700;
  font-size: 16px;
  background: #F7F8FB;
  border-radius: 6px;
}
.star_icon {
  width: 20px;
  height: 30px;
  margin-left: 4px;
}
.star_li_select {
  background: var(--Selected-Green);
  color: var(--Brand-Green);
}
.star_icon_img {
  // background-position: 0px -338px;
  width: 18px;
  height: 18px;
  background-image: url('//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/Pc_Home_hotel_Star.svg');
  background-size: contain;
}
</style>

<style>
  .hotel_sprite_img {
    background-image: url('https://pic5.hopegoocdn.com/i/ori/1xjEJpOQwk8.png') !important;
  }
</style>

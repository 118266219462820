<script setup>
import { useHotelStore } from '~/store/hotel'

const emit = defineEmits(['onDone'])
const hotelStore = useHotelStore()
const { t } = useLang()

function isSelect(n) {
  return n && n.cityName && n.cityName === hotelStore.city.showName && n.cityName === hotelStore.city.cityName
}

function selectCity(n, typeName) {
  hotelStore.setCity(n)
  hotelStore.hideAllLayer()
  console.log('点击推荐城市', $utils.json(n))
  emit('onDone')
  $utils.sendEvent('', '', '酒店主搜', `^城市^模块:${typeName}^城市ID:${n.cityId}^城市名称:${n.cityName}^`)
}
</script>

<template>
  <div v-show="hotelStore.hotCityList.length || hotelStore.hotContinentCityList.length" class="city_body PingFang_SC" @click.stop>
    <el-scrollbar class="city_body_scroll" height="450px">
      <div class="block">
        <div class="title">
          {{ t('home.s_hotel_city_s_title') }}
        </div>
        <div class="ul flex flex-wrap">
          <div v-for="(n, i) in hotelStore.hotCityList" :key="`${i}${n.cityId}`" :title="n.cityName" class="li flex-none line-clamp-1" :class="{ li_select: isSelect(n) }" @click.stop="selectCity(n, '热门目的地')">
            {{ n.cityName }}
          </div>
        </div>
      </div>

      <div v-for="(m, j) in hotelStore.hotContinentCityList" :key="`${j}${m.continentId}`" class="block">
        <div class="title">
          {{ m.continentName }}
        </div>
        <div class="ul flex flex-wrap">
          <div v-for="(n, i) in m.cityList" :key="`${i}${n.cityId}`" :title="n.cityName" :class="{ li_select: isSelect(n) }" class="li flex-none line-clamp-1" @click.stop="selectCity(n, m.continentName)">
            {{ n.cityName }}
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<style scoped lang="scss">
.city_body {
  position: absolute;
  left: 0;
  top: 100%;
  width: 660px;
  margin-top: 10px;

  background: white;
  border-radius: 6px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
.city_body_scroll {
  padding: 10px;
}
.title {
  line-height: 60px;
  padding-left: 10px;
  padding-top: 4px;
  font-weight: 600;
  font-size: 18px;
 }
 .block+.block { border-top: solid 1px #E4E4E4; }
 .ul {
  padding-bottom: 14px;
 }
.li {
  width: 160px;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
}
.li_select { color: var(--Brand-Green); }
.li:hover {
  color: #fff;
  background: var(--Disable-Green);
  // background: #FFF7D4;
  border-radius: 4px;
}
</style>

<script setup>
import { useHotelStore } from '~/store/hotel'

const emit = defineEmits(['onDone'])
const hotelStore = useHotelStore()
const { t } = useLang()

const agesSelect = [{ value: '0', title: '<1' }]
for (let i = 1; i <= 17; i++) {
  agesSelect.push({ value: i.toString(), title: i.toString() })
}

const data = reactive({
  adult: {
    value: hotelStore.adult,
    max: 10,
    min: 1,
    adult: 1,
  },
  child: {
    value: hotelStore.child,
    max: 3,
    min: 0,
    child: 1,
  },
  ages: $utils.json(hotelStore.ages),
  agesSelect,
})

function sub(obj) {
  if (!checkMin(obj)) { return }
  obj.value--
  obj.child && data.ages.pop()
}
function add(obj) {
  if (!checkMax(obj)) { return }
  obj.value++
  obj.child && data.ages.push({ value: '0', showSelect: false })
}
function done() {
  hotelStore.adult = data.adult.value
  hotelStore.child = data.child.value
  hotelStore.ages = data.ages
  hotelStore.hideAllLayer()
  $utils.sendEvent('', '', '酒店主搜', `^客人^成人:${hotelStore.adult}^儿童:${hotelStore.child}^`)
  emit('onDone')
}

function selectAge(n, item) {
  n.value = item.value
}

function checkMin(obj) {
  return obj.value > obj.min
}
function checkMax(obj) {
  return obj.value < obj.max
}
</script>

<template>
  <div class="guests_box" @click.stop>
    <div class="adu_box">
      <div>
        <div class="adu_t1">
          {{ t('home.s_hotel_guests_Adults') }}
        </div>
        <div class="adu_t2">
          18+ {{ t('home.s_hotel_guests_yearsold') }}
        </div>
      </div>
      <div class="flex">
        <div class="btn_num btn_sub hotel_sprite_img" :class="{ btn_sub_enable: checkMin(data.adult) }" @click="sub(data.adult)" />
        <div class="num_box">
          {{ data.adult.value }}
        </div>
        <div class="btn_num btn_add hotel_sprite_img" :class="{ btn_add_enable: checkMax(data.adult) }" @click="add(data.adult)" />
      </div>
    </div>

    <div class="adu_box adu_box_2">
      <div>
        <div class="adu_t1">
          {{ t('home.s_hotel_guests_Children') }}
        </div>
        <div class="adu_t2">
          0-17 {{ t('home.s_hotel_guests_yearsold') }}
        </div>
      </div>
      <div class="flex">
        <div class="btn_num btn_sub hotel_sprite_img" :class="{ btn_sub_enable: checkMin(data.child) }" @click="sub(data.child)" />
        <div class="num_box">
          {{ data.child.value }}
        </div>
        <div class="btn_num btn_add hotel_sprite_img" :class="{ btn_add_enable: checkMax(data.child) }" @click="add(data.child)" />
      </div>
    </div>

    <div v-if="data.child.value" class="tips">
      {{ t('home.s_hotel_guests_tips') }}
    </div>

    <el-dropdown v-for="(n, i) in data.ages" :key="i" class="age_li_box" trigger="click" max-height="200" :teleported="false" @visible-change="show => { n.showSelect = show }">
      <div class="age_li">
        <span>{{ t('home.s_hotel_guests_Children') }} {{ i + 1 }}</span>
        <span class="age_t2">{{ n.value === '0' ? '&lt;1' : n.value }} {{ t('home.s_hotel_guests_yearsold') }}</span>
        <span class="age_s_icon flex-center" :class="{ age_s_icon_up: n.showSelect }">
          <span class="age_s_icon_img hotel_sprite_img" />
        </span>
      </div>
      <template #dropdown>
        <el-dropdown-menu style="width: 200px;">
          <el-dropdown-item v-for="item in data.agesSelect" :key="item.value" class="age_select_li" @click="selectAge(n, item)">
            {{ item.title }} {{ t('home.s_hotel_guests_yearsold') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>

    <div class="btn_done cursor-pointer" @click="done">
      {{ t('home.s_hotel_guests_Done') }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.guests_box {
  position: absolute;
  left: 0;
  top: 100%;
  width: 300px;
  margin-top: 10px;
  padding: 20px 20px 30px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.adu_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 1;
}
.adu_box_2 {
  margin-top: 30px;
}
.adu_t1 {
  font-weight: 400;
  font-size: 16px;

}
.adu_t2 {
  margin-top: 2px;
  font-size: 12px;
  color: #999;
}

.btn_num {
  width: 18px;
  height: 18px;
}
.btn_sub {
  background-position: 0px -148px;
}
.btn_sub_enable {
  background-position: 0px -186px;
}
.btn_sub_enable:hover {
  background-position: 0px -224px;
}
.btn_add {
  background-position: 0px -34px;
}
.btn_add_enable {
  background-position: 0px -72px;
}
.btn_add_enable:hover {
  background-position: 0px -110px;
}
.num_box {
  width: 30px;
  line-height: 18px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
}

.tips {
  font-size: 12px;
  line-height: 16px;
  margin-top: 20px;
}

.age_li_box {
  display: block;
 }
.age_li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 30px 0 10px;
  margin-top: 15px;
  border-radius: 4px;
  border: solid 1px #E4E4E4;
  font-size: 16px;
}
.age_t2 {
  font-size: 14px;
  color: var(--Brand-Green);
}
.age_s_icon {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
}
.age_s_icon_img {
  background-position: 0px 0px;
  width: 16px;
  height: 14px;
  transform: scale(0.5);
}
.age_s_icon_up {
  transform: translateY(-50%) rotate(180deg);
}

.btn_done {
  float: right;
  width: 110px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 30px;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  background: var(--Brand-Green);
  border-radius: 6px;
}
</style>

<style lang="scss">
.age_select_li {
  display: block;
  text-align: right;
}
// 260px
// .el-dropdown-menu__item:not(.is-disabled):focus
.age_select_li:focus {
  color: #606266 !important;
  background: #EBF9F1 !important;
}
</style>

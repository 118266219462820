<script setup>
import { useHotelStore } from '~/store/hotel'

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  isIntl: {
    type: Boolean,
  },
  extendData: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['clickItem'])

const hotelStore = useHotelStore()

const { data, isIntl, extendData } = toRefs(props)

const isShowNone = computed(() => {
  let flag = true
  if ((props.data && props.data.length) || (props.extendData && props.extendData.length)) {
    flag = false
  }
  return flag
})

const { t } = useLang()

const $typeIdToText = {
  0: '城市',
  1: '行政区',
  2: '景区',
  3: '商圈',
  4: '酒店',
  5: '地标',
  6: '医院',
  7: '学校',
  8: '景点',
  9: '地铁站',
  10: '机场/车站',
  11: '火车站',
  12: '品牌',
  13: '集团',
  14: '商场',
  15: '沙滩',
  16: '国家',
  17: '其他',
  18: '酒店榜单',
  19: '地点',
  43: '设施',
  44: '床型',
  45: '主题',
  46: '星级',
  48: '酒店类型',
  49: '早餐',
  50: '酒店推荐',
  52: '房型',
}
function highLightFun(str, arr, color) {
  const split = str.split('')
  arr.forEach((item) => {
    if (split[item]) {
      split[item] = `<em style="color: ${color}">${split[item]}</em>`
    }
  })
  return split.join('')
}
function concatStr(ele) {
  const arr = []
  if (Number(ele.hotelInfo.commentScore)) {
    arr.push(`${ele.hotelInfo.commentScore} 分`)
  }
  if (ele.hotelInfo.recallReason) {
    arr.push(ele.hotelInfo.recallReason)
  }
  if (ele.hotelInfo.mallName) {
    arr.push(ele.hotelInfo.mallName)
  }
  return arr.join(' | ')
}
function clickItem(item, flag) {
  if (flag === 1) {
    item.isExt = true
  }
  emit('clickItem', item)
  console.log('点击搜索下拉', $utils.json(item))
}

// typeId: 类型Id(2:城市;3:品牌;4:行政区;5:商圈;6:poi;1008:星级;1011:设施服务;1013:优惠;1015:主题;1020:评分;1030:住客印象;1031:酒店类型;1032:点评数量;1034:集团;1035:景区;1036:酒店;1038:酒店推荐:铂金优选/紫金精选;1039:推荐词条;1040:床型;1041:早餐;1042:支付方式;1045:距离;1047:价格区间;1048:特色主题房:麻将房/电脑房;1049:品牌:-:全部经济/舒适/高档/豪华酒店;1100:特殊要求;2001:点评数量;8888:排序;)
</script>

<template>
  <div class="ass-keyword PingFang_SC" :class="isShowNone ? 'noneResult' : ''" @click.stop>
    <ul>
      <li v-for="(ele, idx) in data" :key="`${ele.cityId}|${ele.typeId}|${ele.filterId}|${idx}`" class="ass-item" @click="clickItem(ele)">
        <div class="ass-icon-box">
          <i :type="ele.icon" />
        </div>
        <div class="ass-body">
          <div class="body-left">
            <h3 v-html=" highLightFun(ele.regionName || ele.keywordsName || '', ele.highLightCharIndexs || [], '#f63') " />
            <h4 v-if="1">
              {{ ele.regionNameEn || ele.keywordsNameEn }}
            </h4>
            <h4 v-if="ele.typeId === 1036 && ele.hotelInfo">
              {{ concatStr(ele) }}
            </h4>
            <h4 v-else>
              {{ ele.parentName || ele.address }}{{ isIntl && ele.countryName ? ele.address || ele.parentName ? `,${ele.countryName}` : `${ele.countryName}` : "" }}
            </h4>
          </div>
          <div v-if=" ele.typeId === 1036 && ele.hotelInfo && ele.hotelInfo.discountPrice " class="body-right price">
            <em>{{ hotelStore.showCurrency(ele.currencyCode) }}</em><em class="l">{{ ele.hotelInfo.discountPrice }}</em>
          </div>
          <div v-else class="body-right">
            {{ ele.regionTypeName || ele.iconName || $typeIdToText[ele.icon] || "" }}
          </div>
        </div>
      </li>
    </ul>
    <p v-if="extendData.length" class="otherCityTips">
      {{ t('home.s_hotel_serach_list_tips') }}
    </p>
    <ul v-if="extendData.length">
      <li v-for="ele in extendData" :key="`${ele.cityId}|${ele.typeId}|${ele.filterId}`" class="ass-item" @click="clickItem(ele, 1)">
        <div class="ass-icon-box">
          <i :type="ele.icon" />
        </div>
        <div class="ass-body">
          <div class="body-left">
            <h3 v-html=" highLightFun(ele.regionName || ele.keywordsName || '', ele.highLightCharIndexs || [], '#f63') " />
            <h4 v-if="isIntl">
              {{ ele.regionNameEn || ele.keywordsNameEn }}
            </h4>
            <h4 v-if="ele.typeId === 1036 && ele.hotelInfo">
              {{ concatStr(ele) }}
            </h4>
            <h4 v-else>
              {{ ele.parentName || ele.address }}{{ isIntl && ele.countryName ? ele.address || ele.parentName ? `,${ele.countryName}` : `${ele.countryName}` : "" }}
            </h4>
          </div>
          <div v-if=" ele.typeId === 1036 && ele.hotelInfo && ele.hotelInfo.discountPrice " class="body-right price">
            <em>{{ hotelStore.showCurrency(ele.currencyCode) }}</em><em class="l">{{ ele.hotelInfo.discountPrice }}</em>
          </div>
          <div v-else class="body-right">
            {{ ele.regionTypeName || ele.iconName || $typeIdToText[ele.icon] || "" }}
          </div>
        </div>
      </li>
    </ul>
    <p v-if="isShowNone" class="noneTips">
      {{ t('home.s_hotel_serach_list_noresult') }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.ass-keyword {
  position: absolute;
  top: 80px;
  left: 0;
  width: 362px;
  padding: 3px;
  margin: 0;
  z-index: 9998;
  font-size: 12px;
  color: #555;
  //border: 1px solid #c5c5c5;
  background: #fff;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  max-height: 600px;
  overflow-y: scroll;
  border-radius: 6px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  .ass-item {
    .ass-icon-box {
      display: inline-block;
      vertical-align: middle;
      margin-left: 6px;
      i {
        display: block;
        width: 21px;
        height: 21px;
        background: url("https://file.hopegoocdn.com/hopegoo/web-pc/images/hotel_pc/img/search-icons.png")
          no-repeat -2px -115px / 290px 235px;
      }
      i[type="0"] {
        background-position: -245px -115px;
      }
      i[type="1"] {
        background-position: -120px -175px;
      }
      i[type="2"] {
        background-position: -60px -175px;
      }
      i[type="3"] {
        background-position: -2px -115px;
      }
      i[type="4"],
      i[type="45"] {
        background-position: -180px -116px;
      }
      i[type="5"],
      i[type="19"] {
        background-position: -60px -115px;
      }
      i[type="6"] {
        background-position: -120px -115px;
      }
      i[type="7"] {
        background-position: -90px -116px;
      }
      i[type="8"] {
        background-position: -30px -115px;
      }
      i[type="9"] {
        background-position: -150px -115px;
      }
      i[type="10"],
      i[type="11"] {
        background-position: -150px -175px;
      }
      i[type="12"] {
        background-position: -30px -175px;
      }
      i[type="13"] {
        background-position: -2px -175px;
      }
      i[type="14"] {
        background-position: -210px -115px;
      }
      i[type="15"] {
        background-position: -90px -145px;
      }
      // TODO 没有? 16,17,19,40,41,42,47,51,53,54
      // 16,17找不到对应ICON
      i[type="18"],
      i[type="50"] {
        background-position: -90px -175px;
      }
      i[type="43"] {
        background-position: -150px -205px;
      }
      i[type="44"] {
        background-position: -60px -205px;
      }
      i[type="46"] {
        background-position: -120px -205px;
      }
      i[type="48"] {
        background-position: -180px -205px;
      }
      i[type="49"] {
        background-position: -245px -205px;
      }
      i[type="52"] {
        background-position: -180px -144px;
      }
    }
    .ass-body {
      display: inline-block;
      vertical-align: middle;
      width: 305px;
      padding: 12px 15px;
      border-bottom: 1px dashed #dcdcdc;
      .body-left {
        display: inline-block;
        vertical-align: middle;
        width: 230px;
        line-height: 20px;
        h3 {
          font-size: 14px;
          color: #555;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          em {
            color: #f63;
          }
        }
        h4 {
          color: #888;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .body-right {
        display: inline-flex;
        align-items: baseline;
        width: 70px;
        text-align: right;
        vertical-align: middle;
        justify-content: flex-end;
        &.price {
          color: #2a2a2a;
          font-size: 12px;
          em {
            color: #f55;
            &.l {
              font-size: 16px;
            }
          }
        }
      }
    }
    &:hover {
      background: #f8f8f8;
    }
    &:last-child {
      .ass-body {
        border: 0;
      }
    }
  }
  .otherCityTips {
    height: 30px;
    line-height: 30px;
    background: #f5f5f5;
    text-indent: 8px;
    font-size: 12px;
    color: #888;
  }
  .noneTips {
    line-height: 16px;
    padding: 10px 2px;
    font-size: 12px;
    color: #aaa;
  }
  &.noneResult {
    width: 200px;
  }
}
.ass-keyword, .ass-body { box-sizing: content-box; }
</style>

<style lang="scss">
.ass-keyword em {
    font-style: normal;
  }
</style>

<script setup>
import { useHotelStore } from '~/store/hotel'

const emit = defineEmits(['onDone'])
const hotelStore = useHotelStore()

function selectKeywords(n) {
  console.log('点击推荐关键词', $utils.json(n))
  hotelStore.setKeywords(n)
  hotelStore.hideAllLayer()
  emit('onDone')
  $utils.sendEvent('', '', '酒店主搜', `^关键词^${n.name}^${n.filterId}^${n.typeId}^`)
}
</script>

<template>
  <div v-show="hotelStore.hotKeywords.length" class="kw_body PingFang_SC" @click.stop>
    <el-scrollbar class="city_body_scroll" height="450px">
      <div v-for="(m, j) in hotelStore.hotKeywords" :key="`${j}${m.name}`" class="block">
        <div class="title">
          {{ m.name }}
        </div>
        <div class="ul flex flex-wrap">
          <div v-for="(n, i) in m.subFilters" :key="`${i}${n.name}`" :title="n.name" class="li flex-none line-clamp-1" @click.stop="selectKeywords(n)">
            {{ n.name }}
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<style scoped lang="scss">
.kw_body {
  position: absolute;
  right: 0;
  top: 100%;
  width: 660px;
  margin-top: 10px;

  background: white;
  border-radius: 6px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
.city_body_scroll {
  padding: 10px;
}
.title {
  line-height: 60px;
  padding-left: 10px;
  padding-top: 4px;
  font-weight: 600;
  font-size: 18px;
 }
 .block+.block { border-top: solid 1px #E4E4E4; }
 .ul {
  padding-bottom: 14px;
 }
.li {
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  word-break: break-all;
  white-space: break-spaces;
  max-width: 100%;
}
.li_select { color: var(--Brand-Green); }
.li:hover {
  color: #fff;
  background: var(--Brand-Green);
  border-radius: 4px;
}
</style>
